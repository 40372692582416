import Vue from 'vue'
import './plugins/fontawesome'
import App from './App.vue'
import Login from './views/Login'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import VueToast from 'vue-toast-notification';

// Vue numeric
import VueNumeric from 'vue-numeric';

// Date picker
import datePicker from 'vue-bootstrap-datetimepicker';

import axios from 'axios';
import router from './router'

import VueCookies from 'vue-cookies'
import Tools from './tools'

Vue.use(datePicker);
Vue.use(VueNumeric);
Vue.use(VueToast);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueCookies);

let instance = Vue.$toast.open('You did it!');
instance.dismiss();
Vue.$toast.clear();

if (process.env.NODE_ENV == 'development') {
    Vue.BASE_URL = Vue.prototype.BASE_URL = '';
} else { // Production
    // Vue.BASE_URL = Vue.prototype.BASE_URL = '/stimex_comptabilite';
    // Vue.BASE_URL = Vue.prototype.BASE_URL = '/PROJETS/stimex_comptabilite';
    Vue.BASE_URL = Vue.prototype.BASE_URL = '';
}

Vue.config.productionTip = false


axios.get(Vue.BASE_URL + "/users/getuserconfig").then(function() {});
var user = {
    users_compta_id: Vue.$cookies.get("users_compta_id"),
    users_compta_role: Vue.$cookies.get("users_compta_role"),
    users_compta_nom: Vue.$cookies.get("users_compta_nom"),
    users_compta_exercice: Vue.$cookies.get("users_compta_exercice"),
    users_gest_com_token: Vue.$cookies.get("users_gest_com_token"),
    users_gest_com_statut: Vue.$cookies.get("users_gest_com_statut"),
};


var vm = null;
// alert(user.users_gest_com_statut);
if (!Tools.Valider(user.users_compta_id, user.users_gest_com_token) || user.users_gest_com_statut != "EXIST") {
    vm = new Vue({
        router,
        render: h => h(Login)
    }).$mount('#app');
} else {
    vm = new Vue({
        router,
        render: h => h(App, {
            props: {
                users_info: user
            }
        })
    }).$mount('#app');
}



router.beforeEach((to, from, next) => {

    axios.get(Vue.BASE_URL + "/users/getuserconfig").then(function() {});
    var user = {
        users_compta_id: Vue.$cookies.get("users_compta_id"),
        users_compta_role: Vue.$cookies.get("users_compta_role"),
        users_compta_nom: Vue.$cookies.get("users_compta_nom"),
        users_compta_exercice: Vue.$cookies.get("users_compta_exercice"),
        users_gest_com_token: Vue.$cookies.get("users_gest_com_token"),
        users_gest_com_statut: Vue.$cookies.get("users_gest_com_statut"),
    };
    vm.$emit('change-load', 'loading', user);
    // alert(user.users_gest_com_statut);
    if (!Tools.Valider(user.users_compta_id, user.users_gest_com_token) || user.users_gest_com_statut != "EXIST") { // || user.users_gest_com_statut != "EXIST"
        location.reload();
    } else {
        next();
    }
});


router.afterEach(() => {
    var user = {
        users_compta_id: Vue.$cookies.get("users_compta_id"),
        users_compta_role: Vue.$cookies.get("users_compta_role"),
        users_compta_nom: Vue.$cookies.get("users_compta_nom"),
        users_compta_exercice: Vue.$cookies.get("users_compta_exercice"),
        users_gest_com_token: Vue.$cookies.get("users_gest_com_token"),
        users_gest_com_statut: Vue.$cookies.get("users_gest_com_statut"),
    };
    vm.$emit('change-load', 'loaded', user);
})

vm.$emit('change-load', 'loading', user);
vm.$emit('change-load', 'loaded', user);


// axios.get(Vue.BASE_URL+"/users/getuserconfig").then(function(response) {
//     var user = response.data;
    
//     var vm = null;
//     if (user.users_compta_id == '' || user.users_compta_id == null || user.users_compta_id == undefined) {
//         vm = new Vue({
//             router,
//             render: h => h(Login)
//         }).$mount('#app');
//     } else {
//         vm = new Vue({
//             router,
//             render: h => h(App, {
//                 props: {
//                     users_info: user
//                 }
//             })
//         }).$mount('#app');
//     }

//     router.beforeEach((to, from, next) => {
        
//         vm.$emit('change-load', 'loading', user);
//         axios.get(Vue.BASE_URL+"/users/getuserconfig").then(function(response) {
//             var user = response.data;
//             console.log(user);
//             if (user.users_compta_id == '' || user.users_compta_id == null || user.users_compta_id == undefined) {
//                 location.reload();
//             } else {
//                 next();
//             }
//         });

//     })
//     router.afterEach(() => {
//         vm.$emit('change-load', 'loaded', user);
//     })

//     vm.$emit('change-load', 'loaded', user);
//     setTimeout(function(){
//         vm.$emit('change-load', 'loading', user);
//     }, 2000);
    

// });


